import React, { useContext, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faCartShopping,
  faTruck,
  faAngleRight,
  faAngleLeft,
} from "@fortawesome/free-solid-svg-icons";
import { ApiContext } from "../context/ApiContext";
import "../styles/styles.css";
import returnIcon from "../assets/return.svg";

const OrderProductValues = () => {
  const {
    brands,
    categories,
    subcategories,
    clients,
    representatives,
    data,
    filters,
    setFilters,
    fetchData,
  } = useContext(ApiContext);
  const [endpoint, setEndpoint] = useState("");
  const [dataType, setDataType] = useState("");
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (isInitialLoad) {
      setIsInitialLoad(false);
      return;
    }
    fetchData(endpoint);
  }, [endpoint, fetchData, filters, isInitialLoad]);

  const handleButtonClick = (newEndpoint, newDataType, classBtn) => {
    setEndpoint(newEndpoint);
    setDataType(newDataType);
    console.log(
      `Fetching data for endpoint: ${newEndpoint} with filters:`,
      filters
    );
    fetchData(newEndpoint);

    document.querySelectorAll(".buttonContainer button").forEach((btn) => {
      btn.classList.remove("selectedButton");
    });

    document.querySelector(classBtn).classList.add("selectedButton");
  };

  const redirectApp = () => {
    window.location.href =
      "https://app.forca-de-vendas.e-catalogos.net/functions";
  };

  const handleApplyFilters = () => {
    const dateFilters = {
      startDate: filters.startDate,
      endDate: filters.endDate,
      deliveryStartDate: filters.deliveryStartDate,
      deliveryEndDate: filters.deliveryEndDate,
    };
    console.log(
      `Applying filters with endpoint: ${endpoint} and filters:`,
      dateFilters
    );
    fetchData(endpoint, dateFilters);
  };

  const renderTable = () => {
    if (!endpoint) {
      return <p>Nenhuma referência selecionada.</p>;
    }
    if (!data || !data.results) return null;
    console.log(data.results);
    const BRL = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    });

    return (
      <div>
        <div className="totalContainer">
          <FontAwesomeIcon icon={faAngleRight} className="rightArrow" />
          <div className="totalValuesContainer">
            <h4>N° Produtos:</h4>
            <span>{data.totalSalesCountSum}</span>
          </div>
          <div className="totalValuesContainer">
            <h4>Valor Total R$:</h4>
            <span>{BRL.format(data.totalValueSum)}</span>
          </div>
          <FontAwesomeIcon icon={faAngleLeft} className="leftArrow" />
        </div>
        <div className="table-container">
          <table>
            <thead>
              <tr className="trTitle">
                {dataType === "references" && (
                  <th className="clName">Referência</th>
                )}
                {dataType === "categories" && (
                  <th className="clName">Categoria</th>
                )}
                {dataType === "clients" && <th className="clName">Cliente</th>}
                {dataType === "subcategories" && (
                  <th className="clName">Subcategoria</th>
                )}
                {dataType === "skus" && (
                  <th className="clName">Referência(SKU)</th>
                )}
                {dataType === "brands" && <th className="clName">Marca</th>}
                {dataType === "representatives" && (
                  <th className="clName">Representante</th>
                )}
                <th className="clValue">Valor R$</th>
                <th className="clValuePorcent">(%) Valor</th>
                <th className="clQtde">Qtde.</th>
                <th className="clQtdePorcent">(%) Qtde.</th>
              </tr>
            </thead>
            <tbody>
              {data.results.map((result, index) => (
                <tr key={index}>
                  {dataType === "references" && (
                    <td className="clName">{result.reference}</td>
                  )}
                  {dataType === "categories" && (
                    <td className="clName">{result.category}</td>
                  )}
                  {dataType === "clients" && (
                    <td className="clName">{result.name}</td>
                  )}
                  {dataType === "subcategories" && (
                    <td className="clName">{result.subcategory}</td>
                  )}
                  {dataType === "skus" && (
                    <td className="clName">{result.reference}</td>
                  )}
                  {dataType === "brands" && (
                    <td className="clName">{result.brand}</td>
                  )}
                  {dataType === "representatives" && (
                    <td className="clName">{result.representative}</td>
                  )}
                  <td className="clValue">{BRL.format(result.totalValue)}</td>
                  <td className="clValuePorcent">
                    {result.porcentTotalValue}%
                  </td>
                  <td className="clQtde">{result.salesCount}</td>
                  <td className="clQtdePorcent">{result.porcentSalesCount}%</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <div className="container">
      <header className="headerCrm">
        <h1>CRM/Relatório</h1>
        <img
          src={returnIcon}
          alt="Return"
          className="headerOption"
          onClick={redirectApp}
        />
      </header>
      <div className="brandContainer">
        <div className="brandMenu">
          <label htmlFor="brand">MARCAS</label>
          <select
            id="brand"
            name="brand"
            value={filters.brand}
            onChange={handleFilterChange}
          >
            <option value="">TODAS</option>
            {brands.map((brand, index) => (
              <option key={index} value={brand}>
                {brand}
              </option>
            ))}
          </select>
        </div>
        <button className="btnOk" onClick={handleApplyFilters}>
          OK
        </button>
      </div>
      <div className="dateContainer">
        <div className="dateFilterContainer">
          <div>
            <label htmlFor="startDate">Pedidos</label>
            <FontAwesomeIcon icon={faCartShopping} />
          </div>
          <div className="dateFilterDiv">
            <input
              type="date"
              id="startDate"
              name="startDate"
              value={filters.startDate}
              onChange={handleFilterChange}
            />
            <input
              type="date"
              id="endDate"
              name="endDate"
              value={filters.endDate}
              onChange={handleFilterChange}
            />
          </div>
        </div>
        <div className="dateFilterContainer">
          <div>
            <label htmlFor="deliveryStartDate">Entregas:</label>
            <FontAwesomeIcon icon={faTruck} />
          </div>
          <div className="dateFilterDiv">
            <input
              type="date"
              id="deliveryStartDate"
              name="deliveryStartDate"
              value={filters.deliveryStartDate}
              onChange={handleFilterChange}
            />
            <input
              type="date"
              id="deliveryEndDate"
              name="deliveryEndDate"
              value={filters.deliveryEndDate}
              onChange={handleFilterChange}
            />
          </div>
        </div>
      </div>
      <div className="parentContainer">
        <div className="buttonContainer">
          <button
            className="btnBra"
            onClick={() => handleButtonClick("/brands", "brands", ".btnBra")}
          >
            Marcas
          </button>
          <button
            className="btnRef"
            onClick={() =>
              handleButtonClick("/orders", "references", ".btnRef")
            }
          >
            Referências
          </button>
          <button
            className="btnVar"
            onClick={() => console.log("Variantes button clicked", ".btnVar")}
          >
            Variantes
          </button>
          <button
            className="btnSKU"
            onClick={() => handleButtonClick("/skus", "skus", ".btnSKU")}
          >
            SKUs
          </button>
          <button
            className="btnCat"
            onClick={() =>
              handleButtonClick("/categories", "categories", ".btnCat")
            }
          >
            Categorias
          </button>
          <button
            className="btnSub"
            onClick={() =>
              handleButtonClick("/subcategories", "subcategories", ".btnSub")
            }
          >
            Subcatego.
          </button>
          <button
            className="btnCli"
            onClick={() => handleButtonClick("/clients", "clients", ".btnCli")}
          >
            Clientes
          </button>
          <button
            className="btnRep"
            onClick={() =>
              handleButtonClick(
                "/representatives",
                "representatives",
                ".btnRep"
              )
            }
          >
            Represent.
          </button>
          <button
            className="btnVar"
            onClick={() => console.log("Variantes button clicked", ".btnVar")}
          >
            Preposto
          </button>
        </div>
      </div>
      <div className="subfilterContainer">
        <input
          type="search"
          name="referenceName"
          placeholder="BUSCAR"
          onChange={handleFilterChange}
        />
        <div className="selectContainer">
          <select
            name="category"
            value={filters.category}
            onChange={handleFilterChange}
          >
            <option value="">CATEGORIA</option>
            {categories.map((category, index) => (
              <option key={index} value={category}>
                {category}
              </option>
            ))}
          </select>
          <select
            name="subcategory"
            value={filters.subcategory}
            onChange={handleFilterChange}
          >
            <option value="">SUBCATEGORIA</option>
            {subcategories.map((subcategory, index) => (
              <option key={index} value={subcategory}>
                {subcategory}
              </option>
            ))}
          </select>
          <select
            name="clientId"
            value={filters.clientId}
            onChange={handleFilterChange}
          >
            <option value="">CLIENTE</option>
            {clients.map((client, index) => (
              <option key={index} value={client.id}>
                {client.name}
              </option>
            ))}
          </select>
          <select
            name="representativeId"
            value={filters.representativeId}
            onChange={handleFilterChange}
          >
            <option value="">REPRESENTANTE</option>
            {representatives.map((representative, index) => (
              <option key={index} value={representative.id}>
                {representative.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="divTable">{data && <div>{renderTable()}</div>}</div>
    </div>
  );
};

export default OrderProductValues;

// src/App.js

import React from 'react';
import OrderProductValues from './components/CRM';

function App() {
  return (
    <div className="App">
      <OrderProductValues />
    </div>
  );
}

export default App;

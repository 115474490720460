import React, { createContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { buildUrlWithFilters } from '../utils/urlBuilder';

export const ApiContext = createContext();

export const ApiProvider = ({ children }) => {
  const [brands, setBrands] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [clients, setClients] = useState([]);
  const [representatives, setRepresentatives] = useState([]);
  const [data, setData] = useState(null);
  const [filters, setFilters] = useState({
    companyId: '37',
    brand: '',
    category: '',
    subcategory: '',
    clientId: '',
    representativeId: '',
    referenceName: '',
    startDate: '',
    endDate: '',
    deliveryStartDate: '',
    deliveryEndDate: ''
  });

  const fetchBrands = useCallback(async () => {
    try {
      let url = `https://api.app.forca-de-vendas.crm.e-catalogos.net/brands?companyId=${filters.companyId}`;
      if (filters.subcategory) {
        url += `&subcategory=${filters.subcategory}`;
      }
      if (filters.clientId) {
        url += `&clientId=${filters.clientId}`;
      }
      if (filters.category) {
        url += `&category=${filters.category}`;
      }
      if (filters.representativeId) {
        url += `&representativeId=${filters.representativeId}`;
      }
      const response = await axios.get(url);
      setBrands(response.data.results.map(b => b.brand));
    } catch (error) {
      console.error('Error fetching brands', error);
    }
  }, [filters.companyId, filters.subcategory, filters.clientId, filters.category, filters.representativeId])

  const fetchCategories = useCallback(async () => {
    try {
      let url = `https://api.app.forca-de-vendas.crm.e-catalogos.net/categories?companyId=${filters.companyId}`;
      if (filters.brand) {
        url += `&brand=${filters.brand}`;
      }
      if (filters.subcategory) {
        url += `&subcategory=${filters.subcategory}`;
      }
      if (filters.clientId) {
        url += `&clientId=${filters.clientId}`;
      }
      if (filters.representativeId) {
        url += `&representativeId=${filters.representativeId}`;
      }
      const response = await axios.get(url);
      setCategories(response.data.results.map(c => c.category));
    } catch (error) {
      console.error('Error fetching categories', error);
    }
  }, [filters.companyId, filters.brand, filters.subcategory, filters.clientId, filters.representativeId]);

  const fetchSubcategories = useCallback(async () => {
    try {
      let url = `https://api.app.forca-de-vendas.crm.e-catalogos.net/subcategories?companyId=${filters.companyId}`;
      if (filters.brand) {
        url += `&brand=${filters.brand}`;
      }
      if (filters.category) {
        url += `&category=${filters.category}`;
      }
      if (filters.clientId) {
        url += `&clientId=${filters.clientId}`;
      }
      if (filters.representativeId) {
        url += `&representativeId=${filters.representativeId}`;
      }
      const response = await axios.get(url);
      setSubcategories(response.data.results.map(s => s.subcategory));
    } catch (error) {
      console.error('Error fetching subcategories', error);
    }
  }, [filters.companyId, filters.brand, filters.category, filters.clientId, filters.representativeId]);

  const fetchClients = useCallback(async () => {
    try {
      let url = `https://api.app.forca-de-vendas.crm.e-catalogos.net/clients?companyId=${filters.companyId}`;
      if (filters.brand) {
        url += `&brand=${filters.brand}`;
      }
      if (filters.category) {
        url += `&category=${filters.category}`;
      }
      if (filters.subcategory) {
        url += `&subcategory=${filters.subcategory}`;
      }
      if (filters.representativeId) {
        url += `&representativeId=${filters.representativeId}`;
      }
      const response = await axios.get(url);
      setClients(response.data.results.map(c => ({ id: c.client_id, name: c.name })));
    } catch (error) {
      console.error('Error fetching clients', error);
    }
  }, [filters.companyId, filters.brand, filters.category, filters.subcategory, filters.representativeId]);

  const fetchRepresentatives = useCallback(async () => {
    try {
      let url = `https://api.app.forca-de-vendas.crm.e-catalogos.net/representatives?companyId=${filters.companyId}`;
      if (filters.brand) {
        url += `&brand=${filters.brand}`;
      }
      if (filters.category) {
        url += `&category=${filters.category}`;
      }
      if (filters.subcategory) {
        url += `&subcategory=${filters.subcategory}`;
      }
      if (filters.clientId) {
        url += `&clientId=${filters.clientId}`;
      }
      const response = await axios.get(url);
      console.log(response.data.results);
      setRepresentatives(response.data.results.map(r => ({ id: r.representative_id, name: r.representative })));
    } catch (error) {
      console.error('Error fetching representatives', error);
    }
  }, [filters.companyId, filters.brand, filters.category, filters.subcategory, filters.clientId]);

  const fetchData = useCallback(async (endpoint) => {
    try {
        const url = buildUrlWithFilters(endpoint, filters);
        const response = await axios.get(url);
        setData(response.data);
    } catch (error) {
        console.error('Error fetching data', error);
    }
  }, [filters]); 


  useEffect(() => {
    fetchBrands();
    fetchCategories();
    fetchSubcategories();
    fetchClients();
    fetchRepresentatives();
  }, [fetchBrands, fetchCategories, fetchSubcategories, fetchClients, fetchRepresentatives, filters.brand, filters.category, filters.subcategories, filters.clientId, filters.representativeId]);

  return (
    <ApiContext.Provider value={{ brands, categories, subcategories, clients, representatives, data, filters, setFilters, fetchData }}>
      {children}
    </ApiContext.Provider>
  );
};

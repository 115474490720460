export const buildUrlWithFilters = (endpoint, filters) => {
  const params = new URLSearchParams();
  params.append('companyId', filters.companyId);
  if (filters.brand) params.append('brand', filters.brand);
  if (filters.category) params.append('category', filters.category);
  if (filters.subcategory) params.append('subcategory', filters.subcategory);
  if (filters.clientId) params.append('clientId', filters.clientId);
  if (filters.representativeId) params.append('representativeId', filters.representativeId);
  if (filters.referenceName) params.append('referenceName', filters.referenceName);
  if (filters.startDate) params.append('startDate', filters.startDate);
  if (filters.endDate) params.append('endDate', filters.endDate);
  if (filters.deliveryStartDate) params.append('deliveryStartDate', filters.deliveryStartDate);
  if (filters.deliveryEndDate) params.append('deliveryEndDate', filters.deliveryEndDate);

  return `https://api.app.forca-de-vendas.crm.e-catalogos.net${endpoint}?${params.toString()}`;
};
